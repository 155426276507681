import React from 'react';
import { Link } from "gatsby";
import * as Markdown from 'react-markdown';
import style from "../Content/Content.module.css";

const CareerListItem = ({job, ...props}) => {
    const { title, jobSummary, publishDate, slug } = job;
    return (
        <>
            <h3><Link className={`uk-link uk-text-bold uk-link-reset`} to={`/careers/${slug}`}>{title}</Link></h3>
            <Markdown
                source={jobSummary.childMarkdownRemark.html}
                escapeHtml={false}
            />
            <Link className={`uk-link uk-text-bold uk-link-reset`} to={`/careers/${slug}`}><strong>Apply now</strong></Link>
        </>
    );
};

export default CareerListItem

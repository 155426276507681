import React, { useEffect, useState } from "react";
import axios from "axios"
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import FileUpload from "../Contact/FileUpload";
import style from "../Contact/Contact.module.css";

async function postForm(body) {
    const endpoint = 'https://api.sendgrid.com/v3/mail/send';
    console.log("POST FORM BODY", body)
    const data = {
        "personalizations": [{
            "to": [{
                "email": "michael.lisboa@gmail.com",
                "name": "Michael Lisboa"
            }],
            "subject": "Hello, World!"
        }],
        "content": [{
            "type": "text/plain",
            "value": `${body.message}`
        }],
        "from": {
            "email": `${body.email}`,
            "name": `${body.first_name} ${body.last_name}`
        },
        "reply_to": {
            "email": `${body.email}`,
            "name": `${body.first_name} ${body.last_name}`
        }
    }

    console.log("EMAIL DATA", data)

    const result = await axios.post(endpoint, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.GATSBY_SENDGRID_KEY_VALUE}`
        }
    })
    console.log("FORM RES", result)
    const res = await result.data;

    return res;
}

const CareersForm = props => {
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        errors,
        watch,
        formState
    } = useForm({
        mode: "all",
    });
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalShown, setIsModalShown] = useState(false)
    const [responseMessage, setResponseMessage] = useState({
        subject: "Subject line",
        content: "Message content"
    })

    useEffect(
        () => {
            if(document.getElementById("SubmitAuditForm") && document.getElementById("SubmitAuditForm").length) {
                document.getElementById("SubmitAuditForm").disabled = true;
            }
        }, [isSubmitting]
    )

    const resetForm = () => {
        setIsModalShown(false)
    }

    const submit = async (data, e) => {
        e.preventDefault()
        setIsSubmitting(true)

        const body = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            resumeUpload: data.resumeUpload,
            message: data.message,
            timestamp: Date.now(),
        }

        postForm(body)
        .then(function (response) {
            console.log("POST FORM RESPONSE", response)
            setResponseMessage({
                subject: response.message,
                content: "Thank you for contacting us. We will respond shortly."
            })
            setIsModalShown(true)
            setIsSubmitting(false)
        })
        .catch(function (error) {
            console.log("FIRST CATCH ERROR", error)
            console.log("FIRST CATCH ERROR", error.response)
            setIsSubmitting(false)
        })

    };

    const handleFileChange = e => {
        console.log("GOT A FILE", e.target.value)
    }

    return (
        <div className="uk-container uk-container-xsmall uk-margin-xlarge">
            <div style={{backgroundColor: "#f6f6f6"}} className="uk-card uk-box-shadow-medium uk-border-rounded">
                <div className="uk-card-header">
                    <h4 className="uk-margin-medium-top">Send us your info, we'd love to hear from you!</h4>
                </div>
                <div className="uk-card-body">
                    <form id="careersForm" className="careers-form" onSubmit={(e) => handleSubmit(submit)(e)}>
                        <fieldset className="uk-fieldset uk-text-left">
                            <div className="uk-grid uk-grid-medium uk-child-width-1-2@s" data-uk-grid>
                                <div className="uk-margin-small-top">
                                    <label htmlFor="first_name" className={`uk-text-small ${errors.first_name ? "uk-text-danger" : "uk-text-muted"}`}>
                                    {errors.first_name ?
                                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                    :
                                    <small className="uk-text-danger uk-display-inline">*</small>} First name {errors.first_name?.type === "required" && "is required"}
                                        <input
                                            id="first_name"
                                            name="first_name"
                                            ref={register({required: true})}
                                            type="text"
                                            className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="uk-margin-small-top">
                                    <label htmlFor="last_name" className={`uk-text-small ${errors.last_name ? "uk-text-danger" : "uk-text-muted"}`}>
                                    {errors.last_name ?
                                    <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                    :
                                    <small className="uk-text-danger uk-display-inline">*</small>} Last name {errors.last_name?.type === "required" && "is required"}
                                        <input
                                            id="last_name"
                                            name="last_name"
                                            ref={register({required: true})}
                                            type="text"
                                            className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                            autoComplete="off"
                                            autoCorrect="off"
                                            required
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="uk-margin-small-top">
                                <label htmlFor="email" className={`uk-text-small ${errors.email ? "uk-text-danger" : "uk-text-muted"}`}>
                                {errors.email ?
                                <span className="uk-text-danger uk-display-inline" data-uk-icon="icon: warning; ratio: 0.8" />
                                :
                                <small className="uk-text-danger uk-display-inline">*</small>} Email address {
                                    errors.email?.type === "required" && "is required" || errors.email && errors.email.message
                                }
                                    <input
                                        id="email"
                                        name="email"
                                        ref={register({
                                          required: true,
                                          pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "is invalid"
                                          }
                                        })}
                                        type="text"
                                        className={`uk-input uk-form-large uk-border-rounded ${errors.message ? "uk-form-danger" : ""}`}
                                        autoComplete="off"
                                        autoCorrect="off"
                                        required
                                    />
                                </label>
                            </div>

                            <div className="uk-margin-small-top">
                                <label htmlFor="message" className={`uk-text-small ${errors.message ? "uk-text-danger" : "uk-text-muted"}`}>
                                Your message
                                    <textarea
                                        id="message"
                                        name="message"
                                        ref={register()}
                                        rows="3"
                                        className={`uk-textarea uk-form-large uk-border-rounded`}
                                        maxLength="1000"
                                        autoComplete="off"
                                        autoCorrect="off"
                                    />
                                </label>
                            </div>

                            <div
                                className="">
                                <FileUpload
                                    name={`resumeUpload`}
                                    register={register}
                                    unregister={unregister}
                                    setValue={setValue}
                                    watch={watch}
                                     />
                            </div>

                            {!isLoading ?

                            <div className="uk-text-center uk-margin-large">
                                {!formState.isValid  ?
                                    <span style={{color: "#d8d8d8"}} className="uk-text-uppercase uk-text-small">Fill out required fields</span>
                                :
                                    isSubmitting ?
                                    <span style={{color: "#666"}} className="uk-text-uppercase uk-text-small">Just a moment</span>
                                    :
                                    <button
                                        id="SubmitCareerForm"
                                        name="careerForm"
                                        type="submit"
                                        className={`uk-button uk-button-default uk-button-large uk-border-pill`}
                                        >
                                        Send your message
                                    </button>
                                }
                            </div>
                            :
                            null
                            }
                        </fieldset>
                        <Modal
                            isShown={isModalShown}
                            style={{borderStyle: "none"}}>
                            <div className="uk-card uk-card-default">
                                <div className="uk-card-header">
                                    <h4>{responseMessage.subject}</h4>
                                </div>
                                <div className="uk-card-body">
                                    <p>{responseMessage.content}</p>
                                    <div className="uk-text-center uk-margin-large">
                                        <button
                                            className="uk-button uk-button-primary uk-border-pill"
                                            onClick={resetForm}>
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CareersForm;

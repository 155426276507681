import React from "react";
import CareerListItem from "./CareerListItem";

const CareersList = ({data}) => {

    return  (
        <div className="uk-container uk-container-xsmall">
            {data.map(post =>
                <CareerListItem
                    job={post.node}
                    key={post.node.slug} />
            )}
        </div>
    )
}

export default CareersList;

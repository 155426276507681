import React from "react"
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import CareerPostListItem from '../components/Content/CareerPostListItem';
import CareersList from "../components/Careers/CareersList";
import CareersForm from "../components/Careers/CareersForm";
import SEO from "../components/seo"
import style from "../components/Content/Content.module.css";

const Careers = ({data, ...props}) => {
    let careersList = data.allContentfulCareerPost.edges;
    careersList.sort((a, b) => new Date(b.node.publishDate).getTime() - new Date(a.node.publishDate).getTime());
    const heroImage = data.contentfulHeroCta.imageFile.localFile.childImageSharp.fluid

    return (
        <Layout>
            <SEO title="Join our growing team" />
            <Helmet>
            </Helmet>
            <BackgroundImage
                    Tag="div"
                    fluid={heroImage}
                    preserveStackingContext={true}
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: `center center`,
                        display: `flex`,
                        alignItems: `center`,
                        height: `100%`,
                        minHeight: "70vh"
                      }}
                  >
                <div id={data.slug} className="uk-container uk-position-relative uk-light">
                    <div className={`${style.topWrapHeight} uk-flex uk-flex-middle`}>
                        <div
                            style={{minWidth: "80%"}}
                            className={`${style.topContainer} uk-position-relative uk-margin-medium-top`}
                            data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                            <div
                                className={`${style.heroCopy} uk-width-1-2@s`}
                                data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                                <h1>Help us make brighter futures a possibility for everyone</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            {careersList.length ?
            <section className="uk-section uk-section-large">
                <div className="uk-container uk-container-xsmall uk-margin-large-bottom">
                    <h2>Join our growing team</h2>
                    <p className="uk-text-lead">Check out our latest job openings.</p>
                </div>
                <CareersList data={careersList} />

                <div className="uk-container uk-container-xsmall uk-margin-large-top uk-margin-remove-bottom">
                    <hr />
                    <p className="uk-text-lead uk-margin-small-bottom">Don't see the perfect role for you?</p>
                    <p className="uk-margin-remove-top">
                        We're always looking for talented people. Send your
                        r&eacute;sum&eacute; to <a href="mailto:people@bettertradeoff.com"
                            className="uk-link uk-link-text uk-text-bold" style={{color: "#202020", textDecoration: "underline"}}>people@bettertradeoff.com</a> and we'll reach out when the right opportunity arises.
                    </p>
                </div>
            </section>
            :
            <section className="uk-section uk-section-large">
                <div className="uk-container uk-container-xsmall uk-margin-remove-bottom">
                    <h2>Join our team</h2>
                    <p className="uk-text-lead">We're always looking for talented people. Send us your
                    r&eacute;sum&eacute; and we'll reach out when the right opportunity arises.</p>
                    <p className="uk-text-lead">
                        <a href="mailto:people@bettertradeoff.com"
                            className="uk-button uk-button-large uk-button-secondary uk-border-pill">people@bettertradeoff.com</a>
                    </p>
                </div>
                {/*<CareersForm />*/}
            </section>
            }
        </Layout>
    )
}

export default Careers;

export const careerListQuery = graphql`
    query careerListQuery {
      contentfulHeroCta(imageFile: {title: {regex: "/Careers/"}}) {
        imageFile {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      allContentfulCareerPost {
        edges {
          node {
            title
            publishDate
            jobSummary {
              childMarkdownRemark {
                html
              }
            }
            department
            content {
              json
            }
            slug
          }
        }
      }
    }`
